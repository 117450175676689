@charset "UTF-8";

/* CSS Document */
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

/* tailwind 사용하기 */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables.scss";

body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f6f7f9;
  //height:100%; overflow:auto; position: relative;
}



body,input,textarea,select,table,button{
  font-family: 'Noto Sans KR', sans-serif;
  font-size:12px;
  font-weight:400;
  letter-spacing:-0.3px;
  color: $text_black_color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li, ol, ul{
  list-style:none;
}

table {
  border-collapse:collapse; border-spacing:0;
}

a:link{ text-decoration:none;}

/*드래그 생상*/
::selection { background:#6d86de; color: #FFF; text-shadow: none;}
::-moz-selection { background:#6d86de; color: #FFF; text-shadow: none;}
::-webkit-selection { background:#6d86de; color: #FFF; text-shadow: none;}

/*placeholder 색상*/
::-webkit-input-placeholder { /* 크롬 */color:#a7aab3;}
:-moz-placeholder { /* 파이어폭스 */color:#a7aab3; opacity:1;}
::-moz-placeholder { /* 파이어폭스 */color:#a7aab3; opacity:1;}
:-ms-input-placeholder { /* 인터넷 익스플로러 */ color:#a7aab3;}
::placeholder {color:#a7aab3; opacity:1;}

/*input Number 버튼 숨기기 Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
/* Firefox */
input[type=number] {-moz-appearance: textfield;}