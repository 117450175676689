#TraineeApp{
  min-width: 1200px;
  .content_box{
    height: auto!important;
    .bbb{
      margin:0!important;
    }
  }
}



.trainee_contents{
  box-shadow: 0 5px 20px rgba(0,0,0,0.06);
  margin: 0 auto 130px;

  max-width: 1280px;
  min-width: 1200px;

  overflow: auto;

  // region ******************** 메인 페이지 ***************************
  #trainee_main {
    color:#262629;
    min-height: 500px;
    padding: 100px 70px 50px 70px;

    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
    background-color: #fff;

    .info_text {
      margin-top: 70px;
      margin-left: auto;
      margin-right: auto;

      width: 755px;
      word-break: break-all;
      font-size: 1.2rem;
      line-height: 2rem;
      text-align: left;
      .b_text{
        font-size: 1.4rem;
      }
    }

    .line {
      margin: 90px auto 90px auto;
      width: 400px;
      height: 1px;
      background-color: #000;
    }

    .box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 60px;

      .title{
        padding: 65px 0 0 3%;
        font-size: 26px;
        text-align: left;
        line-height: 36px;
      }

      .info_chart{
        display: flex;
        flex-direction: row;
        .num{
          font-size: 25px;
          font-weight: 500;
          margin-right:15px;
        }
        img{
          width: 170px;
        }
        .desc{
          line-height: 29px;
        }
      }
    }
  }
  .mobile_info_text{
    display: none;
  }
}// endregion ******************** 메인 페이지 ***************************

// START ************** 탭 있는 Trainee 페이지  공통 ********************
#trend{
  .tab_content_item_content{
    height: 500px;
  }
}
.trainee_tap_content{
  width: 1100px;
  overflow: auto;
  color: #4A4A4A;
  margin: 30px auto 90px;
  text-align: center;

  .tab_container{
    display: flex;
    flex-direction: column;

    .tab_header_box{
      margin-top: 48px;
      display: flex;
      flex-direction: row;
      height: 100px;
      border: 1px solid #D8D8D8;
      font-size: 16px;
      .accordion_icon{
        display: none;
      }

      .tab_header_item{
        cursor:pointer;
        height:100%;
        width: 275px;

        line-height: 100px;

        img{
          display:none;
        }

        &:nth-child(2).active{
          border-right: 1px solid #D8D8D8;;
        }
        &:nth-child(3).active, &:nth-child(4).active{
          border-left: 1px solid #D8D8D8;;
          border-right: 1px solid #D8D8D8;;
        }
        &:nth-child(5).active{
          border-left: 1px solid #D8D8D8;;
        }

        &.active{
          background-color: #fff;
          img{
            display: inline-block;
            margin-top: -2px;
          }
        }
      }
    }
    .tab_content_box{
      width:900px;
      margin: auto;

      // 실제 차트 데이트가 들어 있는 곳
      .tab_content_item_box{
        background: white;
        padding: 0 40px 40px 40px;
        border:1px solid #D8D8D8;

        .tab_content_item_title{
          height: 75px;
          line-height: 75px;

          display:flex;
          flex-direction:row;
          font-size: 1.2rem;
          border-bottom: 1px solid #D8D8D8;
          b{
            margin-left:10px;
            margin-right:30px;
          }
          .aaa{
            color: #abb0ba;
          }
        }

        .tab_content_item_content{
          margin-top:30px;

          // START ********************* 순위 박스 *********************
          .ranking_box{
            display: flex;
            flex-direction: column;
            text-align: left;

            li{
              display: flex;
              flex-direction: row;
              align-items:center;
              margin-bottom: 15px;

              .ranking_number{
                padding-left: 4px;
                img{
                  width: 32px;
                  max-width: unset;
                }
                .aaa{
                  display: inline-block;
                  width: 32px;

                  height: 32px;
                  line-height:32px;

                  text-align: center;
                }
              }
              .ranking_dash{
                div{
                  width: 10px;
                  height: 10px;
                  margin: 0 7px 0 25px;
                  background-position: center;
                  display: inline-block;
                  background-repeat: no-repeat;

                  &.rank_same{
                    background-image: url(../../../images/icons/rank_same.svg);
                  }
                  &.rank_down{
                    background-image: url(../../../images/icons/rank_down.svg);
                  }
                  &.rank_up{
                    background-image: url(../../../images/icons/rank_up.svg);
                  }
                }
              }
              .ranking_name{
                padding: 0 14px 0 17px;
                font-size: 15px;
              }
              .home_image{
                display: inline-block;
                cursor: pointer;
              }
            }
          }// END ********************* *********************
        }
      }

      .bottom_accordion_box{
        .bottom_accordion{
          margin-top:30px;
          background: #fff;
          border: 1px solid #D8D8D8;
          box-sizing: border-box;
          margin-bottom: 30px;

          .accordion_item{
            cursor: pointer;
            text-align: left;
            width: auto;
            margin: -1px auto;
            border-bottom: 1px solid #D8D8D8;
            line-height: 21px;

            .accordion_title_box{
              display:flex;
              flex-direction:row;
              align-items: center;

              border-bottom: 0;
              font-size: 15px;
              padding: 18px 30px 18px 30px;

              //.title_id{
              //  min-width: 130px;
              //  text-align: center;
              //}
              .title_content{
                flex-grow: 1;
                margin-right: 10px;
              }


            }

            .accordion_content_box{
              transition:max-height 0.3s linear; // 1100
              max-height: 0;
              overflow: hidden;

              div{
                font-size: 15px;
                border-top: 1px solid #D8D8D8;
                margin: 0 30px 40px 30px;
                padding: 0;
                p{
                  margin: 30px 15px 0 15px;
                }
                button{
                  margin: 20px 15px 0 15px;
                }
              }

             }
          }
        }
      }
    }
  }
}// END ************** 탭 있는 Trainee 페이지  공통 ********************

// START ****************** 트레이니 아래 배너 부분 공통 ******************
#trainee_bottom_banner{
  width: 100%;
  font-size: 18px;

  .banner{
    height: 163px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content_box{
      width:1100px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .store_btn_box{
        display: flex;
        flex-direction: row;

        .store_btn{
          cursor: pointer;

          &:nth-child(1){
            margin-right:25px;
          }
        }
      }
    }
    &.mobile_with{
      display: none;
    }
  }
  .with, .mobile_with{
    .content_box{
      color:white;
    }
  }
}// END ****************** 트레이니 아래 배너 부분 공통 ******************


// region *********************** 병원 별 평가 상세 결과 ***************************
#result{
  width: 900px;
  margin: 18px auto 0 auto;
  .content_box{
    height: 62vh;
    background: #fff;
    border: 1px solid #D8D8D8;
    .content_header{
      align-items: center;
      margin: 0 27px 0 27px;
      padding: 17px 0 17px 0 ;
      display:flex;
      flex-direction:row;
      font-size: 18px;
      border-bottom: 1px solid #D8D8D8;
      b{
        margin-left:10px;
        margin-right:30px;
      }
      .aaa{
        color: #abb0ba;
      }
    }
    .content{
      padding:50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .chart_box{
        width: 500px;
      }
      .bbb{
        font-size: 21px;
        font-weight: 500;
        margin: 50px auto 60px;
        text-decoration: underline;
        cursor: pointer;
        width: 500px;
      }
    }
  }

  // 공통 모듈 컥스텀
  .url_copy_btn{
    padding-left: 31px!important;
  }
  .data_source{
    margin-top: 25px;
    margin-bottom: 60px;
  }
}
// endregion *********************** 병원 별 평가 상세 결과 ***************************

// region *********************** 공통 모듈 ***************************
// 링크 공유 버튼
.url_copy_btn_box{
  height: 60px;
  display: flex;
  justify-content: right;
  align-items: center;

  .url_copy_btn{
    color: #4568FF;
    cursor: pointer;

    background-image: url(../../../images/icons/url_copy_icon.svg);
    background-repeat: no-repeat;
    background-size: auto 16px;
    background-position: 6px 3px;
    padding-left:20px;

    width: 90px; height: 24px; line-height: 21px;
    background-color: #fff; border:1px solid #4568FF;
    border-radius: 5px;
    right: 0; top: -40px;
    a{
      position:absolute; width: 70px; padding-left: 20px; height: 100%; top: 0px; left: 0px; color: #4568FF;
    }
  }
}

// 데이터 출처
.data_source{
  font-size: 14px;
  color: #9fa0a6;
  text-align: left;
  .copyright{
    font-size: 13px;
    margin-top: 6px;
    color: #c5c6ca;
    word-break: keep-all;
  }
}

// 더보기 버튼
.view_more_btn{
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  background-color: #202532;
  cursor: pointer;
  font-size: 14px;
}
// endregion *********************** 공통 모듈 ***************************


// START ======================================== 0px ~ 480px ========================================
@media all and (max-width: 480px) {
  #TraineeApp, .trainee_contents{
    min-width: inherit;
  }
  .trainee_contents{
    width: 100%!important;
    box-shadow: none;
    margin-bottom: 0;

    // region ************* 메인 페이지 *************
    #trainee_main{
      width: 100vw;
      padding: 50px 5% 0;
      margin: 0;
      box-shadow: 0 5px 20px rgba(0,0,0,0.06);

      .info_text{
       display: none;
      }

      .line{
        display: none;
      }
      .box{
        margin-top: 50px;
        margin-bottom: 20px;

        flex-direction: column;
        align-items: center;
        .title{
          padding: 0;
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 60px;
        }
        .info_chart{
          position: relative;
          margin-bottom: 54px;
          .num{
            position: absolute;
            left: -15%;
            font-size: 14px;
          }
          img{
            width: 150px;
          }
          .desc{
            margin-top: 13px;
            line-height: inherit;
          }
        }
      }
    }
    .mobile_info_text{
      margin: 60px auto 60px;
      width: 80vw;
      display: block;
      font-size: 1rem;
      line-height: 1.95rem;
      .b_text{
        font-size: 17px;
      }
    }
    // region ************* 메인 페이지 *************
  }

  // **************** 아래 배너 부분 *********************
  #trainee_bottom_banner{
    .banner{
      height: 125px;
      .content_box{
        flex-direction: column;
        .in_text{
          font-size: 0.85rem;
        }
        .store_btn_box{
          margin-top: 1rem;
          img{
            width: 100px;
          }
        }
      }
      &.main_store{
        background-position: 118% 39px!important;
        background-size: 185px auto;
        padding: 25px;
      }
      &.mobile_with{
        display: flex;
        font-size: 1.3rem;
        .content_box{
          text-align: center;
          font-size: 14px;
        }
      }
      &.with{
        display: none;
      }
    }
  }
  // ***************** 5개년 트렌드 페이지 *******************
  $mobile_margin: 15px;
  .trainee_tap_content{
    width: 100vw;
    margin: 0 auto 15px;

    .tab_container{
      .tab_header_box{
        flex-direction: column;
        height:  auto;
        border: 1px solid #D8D8D8;
        background-color: #fff;
        position: relative;

        .accordion_icon{
          height: 10px;
          display: block;
          position: absolute;
          right: 20px;
          top: 25px;
          margin-top: 0!important;
        }
        .tab_header_item{
          display: none;
          width: 100%;
          line-height: 60px;
          &.active{
            display: block;
            border: none!important;
         }

        }

        margin: $mobile_margin;
      }
      .tab_content_box{
        width: calc(100vw - 30px); // $mobile_margin * 2를 뺀다
        margin: $mobile_margin;
        .tab_content_item_box {
          padding: 15px 25px 20px 25px;

          .tab_content_item_title{
            height: inherit;
            flex-direction: column;
            line-height: inherit;
            justify-content: center;
            text-align: left;
            padding-bottom: 15px;
            b{
              margin-left: 3px;
              margin-right: 30px;
            }
            .aaa{
              font-size: 13px;
            }
          }
          .tab_content_item_content{
            .ranking_box{
              li{
                .ranking_name{
                  font-size: 13px;
                }
              }
            }
          }
        }
        .bottom_accordion_box{
          .bottom_accordion{
            .accordion_item{
              .accordion_title_box{
                font-size: 13px;
                padding: 10px 20px 10px 20px;
                img{
                  height: 7px;
                }
              }
              .accordion_content_box{
                div{
                  margin: 0 20px 20px 20px;
                  button{
                    margin: 15px 15px 0 15px;
                  }
                  p{
                    font-size: 13px;
                    margin: 20px 15px 0 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 5개년 트렌드 페이지
  #trend{
    .tab_content_item_content{
      margin-top: 15px;
      height: 300px;
    }
  }

  // region ************* 모바일 : 병원 별 평가 상세 결과 *************
  #result{
    width: 100vw;
    padding: $mobile_margin;
    margin: 0 auto 0;

    .content_box{
      height: auto;

      .content_header{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: unset;
        b{
          margin-left: 3px;
          margin-right: 30px;
        }
        .aaa{
          font-size: 0.9rem;
        }
      }

      .content{
        padding:$mobile_margin;

        .chart_box{
          width: calc(100vw - 60px); // $mobile_margin * 4
        }
        .bbb{
          font-size: 14px;
          font-weight: 500;
          margin: 0 auto 20px auto!important;
          width: calc(100vw - 60px); // $mobile_margin * 4
        }
      }
    }

    // 공통 모듈 컥스텀
    .url_copy_btn{
      padding-left: 31px!important;
    }
    .data_source{
      margin-top: 25px;
      margin-bottom: 60px;
    }
  }
  // endregion *********************** 병원 별 평가 상세 결과 ***************************

  // region **************** 공통 요소 *************************
  .url_copy_btn_box{
    height: 38px;
    align-items: flex-start;

    .url_copy_btn{
      height: auto;
    }
  }
  .data_source{
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    .copyright{
      font-size: 11px;
    }
  }
  .blue_btn{
    width: 100px;
    font-size: 1rem;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  #TraineeApp{
    //overflow-y: scroll;
  }
  .view_more_btn{
    height: 37px;
    line-height: 37px;
    font-size: 12px;
  }
  // endregion **************** 공통 요소 *************************
}// END *************************** 0px ~ 500px ***********************************
@media all and (max-width: 280px) {
  #trainee_bottom_banner{
    .content_box{
      font-size: 1rem;
    }
  }
  .trainee_tap_content{
    .accordion_item{
      .title_box{
        font-size: 0.9rem !important;
        .title_id{
          min-width: 80px!important;
        }
      }
    }
    .tab_container{
      .tab_content_box{
        .bottom_accordion_box{
          .bottom_accordion{
            .accordion_item{
              .accordion_content_box{
                div{

                }
              }
            }
          }
        }
      }
    }
  }
  #result{
    .content_box{
      .content{
        .bbb{
          width: 200px;
          margin-top: 30px!important;
        }
      }
    }
  }
}