#footer {
  overflow: auto;
  background-color: #2e3448;
  height: auto;
  color: #fff;
  font-size: 12.5px;
  padding: 70px 0 75px;
  font-weight: 300;
  position: relative;

  .bg_instagram {
    background-image: url(../../images/icons/instagram_icon_white.svg);
  }

  .right_box {
    float: right;
    min-width: 270px;
    margin-right: 80px;
    img {
      width: 26px;
      height: 26px;
      opacity: 0.3;
      filter: alpha(opacity=30);
      margin-top: -2px;
    }
    ul {
      margin-bottom: 96px;
      li {
        float: left;
        margin: 0 10px 0 0;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        &.fl_r {
          float: right;
          margin: 0 0 0 10px;
        }
      }
    }
    .go_btn {
      width: 100%;
      box-sizing: border-box;
      height: 58px;
      line-height: 56px;
      color: #fff;
      font-size: 15px;
      letter-spacing: 0.8px;
      overflow: hidden;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
      }
      &.contact_btn {
        text-align: center;
        background-color: #1f4bd5;
        margin-bottom: 20px;
        cursor: pointer;
      }
      &.trainees_btn {
        text-align: center;
        background-color: #393e51;
        border: 1px solid rgba(255, 255, 255, 0.1);
        letter-spacing: 0px;
        a {
          color: white;
          text-align: left;
          width: auto;
          margin-left: 26px;
        }
        img {
          position: absolute;
          width: 17px;
          height: auto;
          opacity: 0.2;
          filter: alpha(opacity=20);
          right: 26px;
          top: 22.5px;
        }
      }
    }
  }
  .left_box {
    float: left;
    margin-left: 80px;
    width: 277px;
    height: auto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: -0.1px;
    line-height: 18px;
    .logo img {
      width: 100px;
      height: auto;
      opacity: 0.6;
      filter: alpha(opacity=60);
    }
    .copy {
      font-size: 11px;
      color: rgba(255, 255, 255, 0.3);
      margin-top: 10px;
    }
  }

  .app_box {
    width: auto;
    height: auto;
    position: absolute;
    bottom: 15px;
    left: 80px;

    .app_btn {
      cursor: pointer;
      width: 120px;
      height: 45px;
      box-sizing: border-box;
      border: 1px solid rgba(255, 255, 255, 0.15);
      text-align: center;
      line-height: 39px;
      float: left;
      margin-right: 8px;
      padding: 10px;

      img {
        width: auto;
        opacity: 0.6;
        filter: alpha(opacity=60);
      }
    }
  }
  .menu_box {
    width: 630px;
    //min-width: 580px;

    margin: 0 auto;
    height: auto;
    position: relative;
    overflow: auto;
    color: rgba(255, 255, 255, 0.3);
    letter-spacing: 0px;
    font-size: 11px;
    .menu_list {
      width: 105px;
      float: left;
      letter-spacing: -0.5px;
      &:nth-child(1) {
        width: 113px;
      }
      &:nth-child(2) {
        width: 115px;
      }
      a {
        color: rgba(255, 255, 255, 0.6);
      }
      &.w_s {
        width: 80px;
      }
      &.le_t {
        letter-spacing: 0.4px;
      }
      .title {
        width: 80px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.5px;
        padding-bottom: 17px;
        margin-bottom: 17px;
        border-bottom: 3px solid rgba(255, 255, 255, 0.1);
      }
      ul li {
        line-height: 32px;
      }
    }
  }

  // 병원평가용
  .trainee_menu_box {
    &.hidden {
      display: none !important;
    }
    width: 634px;
    margin: 0 auto;
    overflow: auto;
    letter-spacing: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .menu_list {
      width: 126px;
      a {
        display: inline-block;
        width: 90px;
        font-weight: 500;
        letter-spacing: 0px;
        padding-bottom: 17px;
        margin-bottom: 28px;
        border-bottom: 3px solid rgba(255, 255, 255, 0.1);
        white-space: nowrap;

        span {
          font-size: 11px;
          color: rgba(255, 255, 255, 0.2);
        }
        div {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
      &.wide {
        width: 140px;
        a {
          width: 140px;
        }
      }
      &.le_t {
        letter-spacing: 0.8px;
      }
    }
  }

  .modal_main {
    div {
      ol {
        margin-left: 14px;
        li {
          list-style: decimal !important;
        }
      }
    }
  }
}

.terms_martop {
  margin-top: 90px;
}

// START ********************* 팝업에 관한 디자인 설정 *********************
#contact_popup {
  .guide {
    font-weight: bold;
    font-size: 0.8rem;
  }
}
// START ********************* 팝업에 관한 디자인 설정 *********************

@media all and (max-width: 1400px) {
  #footer .right_box {
    margin-right: 60px;
  }

  #footer .left_box {
    margin-left: 60px;
  }

  #footer .left_box .logo img {
    margin-bottom: 20px;
  }

  #footer .app_box {
    left: 60px;
  }

  #footer .app_box .app_btn {
    width: 126px;
  }

  #footer .app_box .app_btn img {
    height: 22px;
  }

  #footer .menu_box {
    width: 580px;
    letter-spacing: 0px;
    font-size: 11px;
  }

  #footer .menu_box .menu_list {
    width: 93px;
  }

  #footer .menu_box .menu_list:nth-child(1) {
    width: 108px;
  }

  #footer .menu_box .menu_list:nth-child(2) {
    width: 110px;
  }

  #footer .menu_box .menu_list.w_s {
    width: 70px;
  }

  #footer .menu_box .menu_list .title {
    width: 66px;
    font-size: 13px;
    padding-bottom: 15px;
    margin-bottom: 24px;
  }

  #footer .menu_box .menu_list ul li {
    line-height: 30px;
  }
  #footer {
    .trainee_menu_box {
      width: 580px;
      .menu_list {
        width: 110px;
        a {
          width: 86px;
          padding-bottom: 15px;
          margin-bottom: 26px;
        }
      }
    }
  }
}

@media all and (max-width: 1280px) {
  #footer {
    font-size: 13px;
    padding: 60px 0 70px;
  }

  #footer .right_box {
    width: 240px;
    margin-right: 40px;
  }

  #footer .right_box img {
    width: 24px;
    height: 24px;
  } /*#footer .right_box ul{ margin-bottom: 70px;}*/ /*20200716 삭제*/

  #footer .right_box ul li {
    margin: 0 9px 0 0;
  }

  #footer .right_box ul li.fl_r {
    margin: 0 0 0 9px;
  }

  #footer .right_box .go_btn {
    height: 50px;
    line-height: 48px;
    font-size: 14px;
  }

  #footer .right_box .go_btn.contact_btn {
    margin-bottom: 10px;
  }

  #footer .right_box .go_btn.trainees_btn p {
    text-align: left;
    width: auto;
    margin-left: 26px;
  }

  #footer .right_box .go_btn.trainees_btn img {
    top: 18px;
  }

  #footer .left_box {
    margin-left: 40px;
    width: 245px;
    font-size: 11.5px;
  }

  #footer .left_box .logo img {
    /*20200716 width 수정*/
    width: 110px;
    margin-bottom: 15px;
  }

  #footer .left_box .copy {
    font-size: 11px;
    /*20200716 margin-top 수정*/
    margin-top: 4px;
  }

  #footer .left_box .address {
    margin-bottom: 3px;
    line-height: 16px;
  } /*20200716 추가*/

  #footer .app_box {
    left: 40px;
  }

  #footer .app_box .app_btn {
    width: 116px;
    height: 40px;
    line-height: 36px;
    margin-right: 8px;
  }

  #footer .app_box .app_btn img {
    height: 19px;
  }

  #footer .menu_box {
    width: 504px;
    font-size: 10px;
    padding-left: 18px;
  }

  #footer .menu_box .menu_list {
    width: 80px;
  }

  #footer .menu_box .menu_list:nth-child(1) {
    width: 94px;
  }

  #footer .menu_box .menu_list:nth-child(1) .title {
    width: 64px;
  }

  #footer .menu_box .menu_list:nth-child(2) {
    width: 94px;
  }

  #footer .menu_box .menu_list.w_s {
    width: 58px;
  }

  #footer .menu_box .menu_list .title {
    width: 58px;
    font-size: 12px;
    margin-bottom: 20px;
  }

  #footer .menu_box .menu_list ul li {
    line-height: 30px;
  }

  // 병원평가 전용
  #footer {
    .trainee_menu_box {
      width: 500px;
      .menu_list {
        width: 114px;
        a {
          width: 80px;
          margin-bottom: 20px;
          .title {
            font-size: 14px;
          }
        }
      }
    }
  }
} // *********************************** 480px ~ 1280
@media all and (max-width: 480px) {
  #footer {
    padding: 20px 36px 40px;
  }
  .bg_instagram {
    background-image: url(../../images/icons/instagram_icon_white.svg);
  }

  #footer .right_box {
    float: none;
    width: 80%;
    margin: 0 auto 36px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.2px;
    font-weight: 400;
  }

  #footer .right_box img {
    margin: 0px;
  }

  #footer .right_box ul {
    margin-bottom: 26px;
    overflow: auto;
  }

  #footer .left_box {
    float: none;
    width: 80%;
    margin: 0 auto;
    overflow: auto;
    font-size: 13px;
    /*20200716 line-height 추가*/
    line-height: 20px;
  }

  #footer .left_box a {
    color: rgba(255, 255, 255, 0.6);
  }
  /*20200716 추가*/

  #footer .left_box .logo img {
    display: none;
  }

  #footer .left_box .copy {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.3);
    margin-top: 6px;
  }

  #footer .app_box {
    width: 80%;
    position: relative;
    bottom: 0px;
    left: 0px;
    margin: 0 auto 36px;
    overflow: auto;
  }

  #footer .app_box .app_btn {
    width: 48%;
    margin-right: 0;
    position: relative;
  }

  #footer .app_box .app_btn.fl_r {
    float: right;
  }

  #footer .app_box .app_btn img {
    height: 21px;
  }

  #footer {
    .menu_box {
      display: none;
    }
    .trainee_menu_box {
      display: none;
    }
  }
}
@media all and (max-width: 480px) {
  #footer {
    width: 100vw;
    .app_box {
      .app_btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
// START *************************** 0px ~ 340px ***********************************
@media all and (max-width: 340px) {
  #footer {
    .left_box {
      font-size: 12px;
      .copy {
        font-size: 11px;
      }
    }
    .right_box {
      min-width: 0;
      ul {
        li {
          font-size: 0.9rem;
        }
      }
      .go_btn {
        &.trainees_btn {
          a {
            margin-left: 34px;
          }
          img {
            display: none;
          }
        }
      }
    }
  }
}
