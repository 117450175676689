#header {
  width: 100%;
  min-width: 1100px;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  transition: all 0.2s ease-out 0s;
  letter-spacing: 0.6px;

  a {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .top_logo {
    height: 26px;
    width: 126px;
    position: absolute;
    top: 22px;
    left: 30px;
    background-image: url(../../images/icons/medistaff_icon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }

  .tab_btn {
    display: none;
  }
  .top_menu_bg {
    display: none;
  }
  .tab_close_btn {
    display: none;
  }

  .top_menu {
    //overflow-x: auto;
    //overflow-y: scroll;
    margin: 0 auto;
    width: auto;

    text-align: left;
    padding-left: 200px;
    color: rgba(255, 255, 255, 0.7);

    ul {
      margin-top: 23px;
      overflow: auto;

      li {
        overflow: auto;
        display: inline-block;
        margin: 0 20px;
        font-size: 14.5px;
        position: relative;
        a:hover {
          color: #1f4bd5;
        }
      }
    }
  }

  .top_icon {
    width: auto;
    right: 10px;
    top: 21px;
    font-size: 15px;
    position: absolute;
    overflow: hidden;
    opacity: 0.7;
    filter: alpha(opacity=70);
    display: flex;
    gap: 16px;
    div {
      float: left;
      overflow: hidden;
      position: relative;
    }

    .sns_box {
      margin-right: 0;
    }
    .app_box {
      margin-right: 0;
    }
    .icon {
      height: 26px;
      width: 26px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .line {
      color: rgba(255, 255, 255, 0.5);
    }
    .cont_box {
      height: 36px;
      line-height: 20px;
      // width: 140px;
      padding: 8px 16px;
      color: #fff;
      border: 1px solid #fff;
      background-color: #ffffff1a;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 1;
    }
    .cont_box_transparent {
      height: 36px;
      line-height: 20px;
      // width: 140px;
      padding: 8px 16px;
      color: #fff;
      // border: 1px solid #fff;
      // background-color: #ffffff1a;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 1;
      // img {
      //   svg > path {
      //     fill: red;
      //   }
      // }
    }
    .bg_instagram {
      background-image: url(../../images/icons/instagram_icon.svg);
    }
    .bg_f {
      background-image: url(../../images/icons/facebook_icon.svg);
    }
    .bg_b {
      background-image: url(../../images/icons/blog_icon.svg);
    }
    .bg_app_i {
      background-image: url(../../images/top_app_ios_icon.svg);
    }
    .bg_app_g {
      background-image: url(../../images/top_app_and_icon.svg);
    }
  }
  // 스크롤 하단으로 내릴때 변경
  &.header_scroll,
  &.error_header {
    //background-color: red;
    background-color: #fff;
    opacity: 1;
    filter: alpha(opacity=100);
    box-shadow: 0 0 30px rgba(20, 24, 41, 0.15);

    .top_logo {
      background-image: url(../../images/icons/medistaff_icon_scroll.svg) !important;
      opacity: 1;
      filter: alpha(opacity=100);
    }

    .top_icon {
      opacity: 1;
      filter: alpha(opacity=100);
      .bg_f {
        background-image: url(../../images/icons/facebook_icon_scroll.svg) !important;
      }
      .bg_b {
        background-image: url(../../images/icons/blog_icon_scroll.svg) !important;
      }
      .bg_app_i {
        background-image: url(../../images/top_app_ios_icon_scroll.svg);
      }
      .bg_app_g {
        background-image: url(../../images/top_app_and_icon_scroll.svg);
      }
    }
    .cont_box {
      // border: 2px solid #1f4bd5 !important;
      background-color: #1f4bd5 !important;
    }
    .cont_box_transparent {
      color: #2e3448 !important;
      span {
        svg {
          fill: #2e3448;
        }
      }
    }
    .conf_cs_btn {
      background-color: white !important;
      color: #1f4bd5;
    }

    .top_menu a {
      color: #2e3448;
    }
  }
}

// ************************************************************************************
@media all and (max-width: 1400px) {
  #header {
    .top_menu {
      ul {
        margin-left: -22px;
        li {
          margin: 0 14px 0 14px;
        }
      }
    }
  }
}
// ************************************************************************************
@media all and (max-width: 1280px) {
  #header {
    height: 66px;

    .top_logo {
      width: 111px;
      height: 23px;
      left: 24px;
    }

    .top_menu {
      margin: 0;
      text-align: left;
      padding-left: 170px;

      ul {
        li {
          overflow: auto;
          display: inline-block;
          margin: 0 13px 0 13px;
          font-size: 14px;
          position: relative;
        }
      }
    }

    .top_icon {
      .icon {
        height: 24px;
        width: 24px;
        font-size: 12px;
      }

      // .cont_box {
      //   height: 28px;
      //   line-height: 20px;
      //   width: 140px;
      //   font-size: 15px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
  }
}
// ************************************************************************************
@media all and (max-width: 1024px) {
  #header {
    .top_menu {
      margin: 0;
      text-align: left;
      padding-left: 164px;
      ul {
        li {
          overflow: auto;
          display: inline-block;
          margin: 0 12px 0 12px;
          font-size: 14px;
          position: relative;
        }
      }
    }
  }
}
// ************************************************************************************
@media all and (max-width: 480px) {
  #header {
    width: 100%;
    height: 56px;
    min-width: 100%;

    &.header_scroll,
    &.error_header {
      .tab_btn {
        background-image: url(../../images/top_tab_btn_scroll.svg);
      }
    }

    .top_logo {
      height: 56px;
      width: 130px;
      top: 0;
      left: 0;
      background-size: auto 20px;
      background-position: center center;
    }

    .top_icon {
      display: none;
    }

    .tab_btn {
      display: block;
      width: 56px;
      height: 56px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(../../images/top_tab_btn.svg);
      background-size: auto 20px;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.7;
      filter: alpha(opacity=70);
    }

    .tab_close_btn {
      display: block;
      width: 56px;
      height: 56px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(../../images/top_tab_btn_close.svg);
      background-size: auto 20px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .top_menu_bg {
      width: 100%;
      height: 100vh;
      background-color: rgba(46, 52, 72, 0.5);
      z-index: 999;
      position: absolute;
      top: 0;
      left: 0;
    }

    .top_menu {
      display: none;
      width: 240px;
      height: 100vh;
      overflow-x: hidden;
      text-align: left;
      background-color: #fff;
      position: fixed;
      right: 0;
      z-index: 9999;
      padding-left: 0;
      min-height: 540px;

      ul {
        margin-top: 16px;
        overflow: auto;
        //display: flex;
        //flex-direction: column;
        li {
          width: 100px;
          line-height: 25px;
          overflow: auto;
          display: block;
          margin: 20px 0 20px 40px;
          font-size: 15px;
          position: relative;
          color: #2e3448;
          padding-left: 10px;
        }

        &:hover {
          color: #2e3448;
        }
      }
    }

    .top_icon {
      opacity: 1;
      filter: alpha(opacity=100);
      z-index: 9999;
      top: 350px;
      right: 0;
      margin: 0 auto;
      // height: 40vh;
      padding: 0 20px;
      width: 100%;
      div {
        float: none;
        display: inline-block;
      }
      .sns_box {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        width: 100%;
        text-align: center;
        top: 228px;
      }
      .sns_box div {
        margin: 8px;
      }
      .app_box {
        margin-top: 24px;
        border-top: 1px solid #efeff1;
        width: 100%;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .line {
        display: none;
      }
      .cont_box {
        height: 48px;
        line-height: 20px;
        font-size: 14px;
        width: 100%;
        border: 0;
        background-color: #1f4bd5;
        box-sizing: border-box;
      }
      .cont_box_transparent {
        height: 48px;
        line-height: 20px;
        font-size: 14px;
        width: 100%;
        border: 0;
        border: 1px solid #2e3448;
        color: #2e3448;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 14px 12px 14px 16px;
        align-items: center;
      }

      .conf_cs_btn {
        margin-top: 10px;
        border: 1px solid #2e3448 !important;
        background-color: white !important;
        color: #2e3448;
        font-weight: 500;
        letter-spacing: -0.3px;
      }
      .bg_f {
        background-image: url(../../images/icons/facebook_icon_scroll.svg);
      }
      .bg_b {
        background-image: url(../../images/icons/blog_icon_scroll.svg);
      }
      .bg_app_i {
        background-image: url(../../images/tab_app_ios_icon.svg) !important;
        width: 100%;
        height: 48px;
        border: 1px solid #2e34481a;
        box-sizing: border-box;
        background-position: center center;
        background-size: auto 30px;
      }
      .bg_app_g {
        background-image: url(../../images/tab_app_and_icon.svg) !important;
        width: 100%;
        height: 48px;
        border: 1px solid #2e34481a;
        box-sizing: border-box;
        background-position: center center;
        background-size: auto 30px;
      }
    }
  }
}

// *****************************************************************************
@media all and (max-width: 340px) {
  #header {
    .top_menu {
      ul {
        margin-top: 10px;
        li {
          margin: 12px 0 12px 40px;
          font-size: 14px;
        }
      }
    }

    .top_icon {
      top: 295px;
      // height: calc(100% - 254px);

      .sns_box {
        bottom: 0;
        top: 200px;
      }

      .app_box {
        margin-top: 18px;
        padding-top: 22px;
      }

      .cont_box {
        height: 40px;
        line-height: 39px;
      }
    }
  }
}
