#press{
  .sub_header .bg3{
    overflow:hidden!important;
  }
  .press_box {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    height: auto;
    margin: -20px auto 20px;
  }
  .press_card {
    width: 520px;
    height: 236px;

    background-color: #fff;
    box-shadow: 0 0 20px rgba(49, 61, 102, 0.05);
    margin: 10px 10px 45px;

    display: flex;
    flex-direction: row;

    &:nth-child(even) {
      margin-left: 30px;
    }

    &:hover{
      box-shadow: 1px 1px 20px rgba(29, 77, 233, 0.3);
    }

    &:hover .text_box .title {
      color: #1f4bd5;
    }
    &:hover .date_box .wrapper_circle {
      background-color: #1f4bd5;
      color: #fff;
    }

    .date_box{
      display: flex;
      flex-direction: column;

      flex-basis: 100px;
      height: 100%;
      padding: 30px 20px 30px 20px;

      .wrapper_circle {
        margin: 0 auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f6f7f9;
        box-shadow: 0 0 10px 0 rgba(207, 209, 215, 0.25) inset;

        .month {
          line-height: 50px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .day{
        font-size: 15px;
        font-weight: 600;
        text-align: center;
      }
    }

    .press_in{
      flex-grow: 1;

      .text_box {
        width: 374px;
        padding-top: 32px;
        height: auto;

        .title {
          font-size: 17px;
          font-weight: 600;
          width: 374px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .sub_title {
          font-size: 13px;
          color: #abaeb6;
          margin-top: 5px;
          margin-bottom: 14px;
          span{
            margin-left: 5px;
          }
        }
        .content{
          width: auto;
          overflow: hidden;
          max-height: 98px;
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          line-height: 24px;
          word-break: break-all;
          color: #6c717f;
        }
      }
    }
  }
}

@media all and (max-width: 480px) {
  #press{
    .sub_header{
      margin-bottom: 20px;
    }
    .full_box{
      padding: 10px 0;

      .press_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        a{
          display: flex;
          justify-content: center;
        }
        .press_card{
          width: 89vw;
          height: 200px;
          margin:10px;

          &:hover{
            box-shadow: unset;
          }

          &:hover .text_box .title {
            color: unset;
          }
          &:hover .date_box .wrapper_circle {
            background-color: unset;
            color:unset;
          }

          .date_box{
            flex-basis: 30px;
            padding: 15px 10px 15px 10px;

            .wrapper_circle{
              width: 40px;
              height: 40px;

              .month{
                line-height:40px;
              }
            }

            .day{
              font-size:13px;
            }
          }
          .press_in{
            overflow:hidden;
            padding:20px;

            .text_box{
              padding-top:0;
              width: 100%;
              overflow: hidden;

              .title {
                width: inherit;
                font-size: 15px;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    .btn_box{
      margin: 0 0 10px 0;
      button{
        width: 89vw;
      }
    }
  }
}



@media all and (max-width: 281px) {
  #press{
    .full_box{
      .press_box{
        .press_card{
          .date_box{
            padding:15px 0 15px 15px;
          }
        }
      }
    }
  }
}