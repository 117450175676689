#trainee_header{
  width: 100%;
  background-color: #202532;
  color: #fff;

  .top_menu {
    padding-left: 30px;
    padding-right: 13px;
    font-size: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    height: 70px;
    line-height: 70px;
    align-items: center;
    .top_logo {
      flex-grow: 1;
      display: flex;
    }

    ul {
      display: flex;
      flex-direction: row;
      li {
        margin: auto 17px auto auto;
        color: rgba(255, 255, 255, 0.35)
      }
    }

    a{
      color: #fff;;
    }
    img{
      height:26px; width: auto;
      opacity: 0.6;
      filter: alpha(opacity=60);
    }
  }
}// END *************************** 480px ~  ***********************************
@media all and (max-width: 480px) {
  #trainee_header{
    .top_menu{
      height: 56px;
      line-height: 56px;
      padding-left: 15px;
      .top_logo{
        img{
          height: 20px;
        }
      }
      ul{
        li{
          margin:auto 11px auto 0;
          img{
            height: 20px;
          }
        }
      }
    }
  }
} // END *************************** 280px ~ 480px ***********************************
@media all and (max-width: 280px) {
  #trainee_header{
    .top_menu{
      ul{
        li{
          margin: auto 9px auto 0;
          &:nth-child(5){
            margin: auto 0 auto 0;
          }
        }
      }
    }
  }
}