.error_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
  text-align: center;

  .error_img{
    width: 700px;
    height: 350px;
  }
  .error_text{
    color:#848484;

  }

  .move_home_btn{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 24px auto 0 auto;
    width: 175px;
    height: 50px;
    border-radius: 16px;
    border: 2px solid #E6E8EE;

    font-size: 18px;
    font-weight: 700;
  }
}

@media all and (max-width: 480px) {
  .error_box{
    width: 100vw;
    height: 550px;
    text-align: center;

    .error_img{
      height: auto;
    }
    .error_text{

    }

    .move_home_btn{


      width: 150px;
      height: 40px;
      border-radius: 16px;
      border: 2px solid #E6E8EE;

      font-size: 15px;
      font-weight: 700;
    }
  }
}