// region ******************* slick 라이브러리 공통 커스텀 ********************
.slick-dots {
  bottom: 175px !important;
  z-index: 10000;
  li {
    margin: 0 2px;
    &.slick-active {
      button {
        &:before {
          color: #1b44c8;
        }
      }
    }
    button {
      &:before {
        opacity: 1;
        color: white;
      }
    }
  }
  .slick-arrow.slick-next {
    display: none !important;
  }
}
.slick-arrow {
  display: none !important;
}
// endregion ******************* slick 라이브러리 공통 커스텀 ********************

// region ******************* 공통 css ********************

.bottom_btn {
  display: inline-block;

  text-align: center;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;

  background-color: #fff;
  border: 1px solid #ebebed;

  margin-right: 6px;

  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.bottom_btn.list {
  background-color: #3863f0;
  color: #fff;
  background-image: url(images/notice_btn_list.svg);
  border: 1px solid #3863f0;

  float: right;
  margin-right: 0;
}

.bottom_btn.prev {
  background-image: url(images/notice_btn_prev.svg);
}

.bottom_btn.next {
  background-image: url(images/notice_btn_next.svg);
}
.pc_btn_box {
  .bottom_btn {
    width: 84px;
    height: 38px;
    line-height: 35px;

    &.list {
      padding-left: 14px;
      background-position: left 16px center;
    }
    &.prev {
      padding: 0 20px 0 30px;
      background-position: left 16px center;
    }
    &.next {
      padding: 0 30px 0 20px;
      background-position: right 16px center;
    }
  }
}
.mobile_btn_box {
  display: none;
  .bottom_btn {
    width: 38px;
    height: 38px;
    line-height: 35px;
    background-position: center;

    //&.list {
    //}
    //&.prev {
    //}
    //&.next{
    //}
  }
}
// endregion ******************* 공통 css ********************
.mo_sub1_accordion {
  display: none;
  height: auto;

  overflow-x: hidden;
  overflow-y: auto;

  position: relative;
} /*20190404 수정*/
.app {
  .color_b {
    color: #2e3448 !important;
  }

  .color_point {
    color: #1f4bd5 !important;
  }

  .scroll_off {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  #wrap {
    width: 100%;
    height: auto;
    min-width: 1100px;
  }

  .box {
    max-width: 1280px;
    margin: 0 auto;
    //min-width: 1100px;
    position: relative;
    overflow: auto;
  }

  .box.main {
    overflow: visible !important;
  }

  .mobile {
    display: none;
  }

  .web {
    display: block;
  }

  .full_box {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 100px 0;
    background-color: transparent;
  }

  .full_box.bg_w {
    background-color: #fff;
  }

  .btn_s {
    width: 380px;
    height: 64px;
    background-color: #3f4a64;
    text-align: center;
    color: #fff;
    border: 0;
    font-size: 20px;
    letter-spacing: 0.8px;
    margin: 0 auto;
    cursor: pointer;
  }

  .btn_s:hover {
    background-color: #364159;
  }

  .btn_box {
    width: 100%;
    height: auto;
    text-align: center;
  }

  #contents {
    width: 100%;
    height: auto;
    position: relative;
    overflow: auto;
  }

  .sub_header {
    height: 390px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center 78%;
    background-size: 102% auto;
  }

  .sub_header:after {
    content: '';
    z-index: 0;
    background: linear-gradient(
      to bottom,
      rgba(36, 37, 44, 0.4) 10%,
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 390px;
  }

  .sub_header.bg1 {
    background-image: url(images/sub_header_bg1.jpg);
  }

  .sub_header.bg2 {
    background-image: url(images/sub_header_bg2.jpg);
    background-size: 100% auto;
    background-position: 60% 75%;
  }

  .sub_header.bg3 {
    background-image: url(images/sub_header_bg3.jpg);
    background-size: 100% auto;
    background-position: center 45%;
  }

  .sub_header.bg4 {
    background-image: url(images/sub_header_bg4.jpg);
    background-position: center 12%;
  }

  .sub_header.career {
    background-image: url(images/sub_header_bg_career.jpg);
    background-size: 100% auto;
    background-position: 60% 66%;
  }

  /*상단 타이틀 제목*/
  .main_title {
    width: auto;
    height: auto;
    color: #fff;
    z-index: 1;
    position: absolute;
    top: 170px;
    left: 164px;
    font-size: 44px;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .main_title.sub {
    text-align: center;
    left: 0;
    width: auto;
    margin-top: 188px;
    position: relative;
    top: 0;
  }

  .main_title p {
    margin-bottom: 20px;
  }

  .main_title .sub {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.3px;
  }

  .title_box .main_title a {
    color: #fff;
  }

  .title_box .main_title img {
    width: 265px;
    height: auto;
    margin-top: -17px;
    margin-right: 24px;
  }

  .title_box .sub_title {
    font-size: 21px;
    display: inline-block;
    color: #fff;
  }

  .title_box .main_title.sub {
    font-size: 22px;
  }

  .title_box .main_title.sub img {
    width: 190px;
    margin-right: 14px;
    margin-top: -14px;
  }

  .sub_header .title_box {
    top: 114px;
  }

  .w_box {
    width: auto;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0 24px rgba(49, 61, 102, 0.06);
    margin-top: -90px;
    padding: 100px 30px 100px;
    margin-bottom: 100px;
    text-align: center !important;
    overflow: auto;
    position: relative;
  }

  /*수련병원 배너*/
  .w_box .trainee_ad {
    width: 100%;
    height: auto;
    margin-top: -70px;
    margin-bottom: 80px;
  }

  .w_box .trainee_ad img {
    width: 100%;
    height: auto;
  }

  .con_title {
    font-size: 30px;
    font-weight: 600;
    width: auto;
    height: auto;
    margin: 0 auto;
    text-align: center;
  }

  .con_title .title_line {
    width: 130px;
    height: 3px;
    margin: 30px auto 0;
    background-color: #efeff1;
  }

  .con_title.left {
    text-align: left;
    margin-left: 164px;
  }

  .con_title.left .title_line {
    margin: 30px 0 0;
  }

  .accordian_icon {
    display: none;
  }

  //****************************************************
  .line_box {
    float: left;
    width: 33.33%;
    height: auto;
    margin-top: 70px;
    font-size: 16px;
    line-height: 28px;
    color: #6c717f;
  }

  .line_box.four {
    width: 25%;
  }

  .line_box .box_px {
    width: 300px;
    margin: 0 auto;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .line_box .icon_oval {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    line-height: 96px;
    background: linear-gradient(to right bottom, #4174ee 20%, #1f4bd5);
    box-shadow: 6px 6px 10px rgba(56, 104, 255, 0.32);
    margin: 0 auto;
  }

  .line_box {
    .icon_oval {
      img,
      svg {
        width: 50px;
        margin: auto;
      }
    }
  }

  .line_box .in_title {
    font-size: 20px;
    font-weight: 600;
    margin: 46px auto 20px;
    color: #2e3448;
  }

  .main_app_s {
    width: auto;
    height: auto;
    overflow: auto;
    margin-left: 164px;
    margin-top: 70px;
  }

  .main_app_s .btn {
    float: left;
    width: 234px;
    height: 78px;
    border: 1px solid #efeff1;
    box-sizing: border-box;
    margin-right: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 150px auto;
    position: relative;
    overflow: hidden;
  }

  .main_app_s .btn a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .bg_app_i {
    background-image: url(images/tab_app_ios_icon.svg);
  }

  .bg_app_g {
    background-image: url(images/tab_app_and_icon.svg);
  }

  .main_app_slide {
    width: 310px;
    height: 426px;
    background-color: #fff;
    box-shadow: 5px 5px 50px rgba(46, 52, 72, 0.2);
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: -100px;
    right: 164px;

    .frame {
      background-image: url(images/iphone_frame.svg);
      width: 366px;
      height: 430px;
      background-size: cover;
      position: absolute;
      top: -4px;
      left: -40px;
      z-index: 3;
    }
    .frame_bg {
      background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 70%,
        rgba(114, 120, 141, 0.4)
      ); /*Safari 5.1-6*/
      background: -o-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 70%,
        rgba(114, 120, 141, 0.4)
      ); /*Opera 11.1-12*/
      background: -moz-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 70%,
        rgba(114, 120, 141, 0.4)
      ); /*Fx 3.6-15*/
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 70%,
        rgba(114, 120, 141, 0.4)
      ); /*Standard*/
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 50px;
    }
  }

  .sub_tab_box {
    width: 100%;
    height: 100px;
    background-color: #f6f7f9;
    font-size: 15px;
    .sub_tab {
      cursor: pointer;
      width: 25%;
      height: 100px;
      float: left;
      overflow: hidden;
      text-align: center;
      position: relative;
      &:hover {
        background-color: #ecedf0;
      }

      img {
        width: 33px;
        height: auto;
        margin: 20px auto 8px;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .sub_tab_box.sub2 .sub_tab {
    width: 33.33%;
  }

  .sub_title_text {
    font-size: 22px;
    line-height: 40px;
    margin: 80px auto 14px;
    font-weight: 600;
    color: #1f4bd5;
    width: auto;
    text-align: center;
    letter-spacing: 0.2px;
    position: relative;
  }

  .sub_title_text img {
    margin: -40px 30px 0 -60px;
  }

  .sub_title_text.img_r img {
    margin: -40px -60px 0 20px;
  }

  .sub_title_text.app {
    margin-top: 0;
  }

  .sub_title_text.no_title {
    margin-top: 30px;
  }

  .sub_con_text {
    font-size: 17px;
    line-height: 28px;
    color: #6c717f;
    width: 1000px;
    margin: 0 auto;
    word-break: keep-all;
    text-align: center;
  }

  .sub_con_text.app {
    width: 640px;
  }

  .sub_con_text.left_pa {
    padding-left: 20px;
  }

  .sub_con_app_l_box {
    width: 50%;
    float: left;
  }

  .sub_con_app_l_box .sub_title_text {
    text-align: left;
    width: 380px;
    margin-top: 138px;
    margin-right: 300px;
  }

  .sub_con_app_l_box .sub_con_text {
    text-align: left;
    width: 380px;
    margin-right: 300px;
  }

  .sub_con_app_l_box.r_box {
    float: right;
  }

  .sub_con_app_l_box.r_box .sub_title_text {
    margin-left: 10px;
  }

  .sub_con_app_l_box.r_box .sub_con_text {
    margin-left: 10px;
  }

  .sub_con_app_l_box.l_box .sub_title_text {
    margin-left: 260px;
  }

  .sub_con_app_l_box.l_box .sub_con_text {
    margin-left: 260px;
  }

  .pa_bottom_0 {
    padding-bottom: 0 !important;
  }

  .pa_bottom_top_0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .sub_con_app_l_box .app_bottom_sh {
    width: 300px;
    height: 364px;
    margin: 55px auto 0;
    margin-left: 190px;
  }

  .sub_con_app_l_box .app_bottom_sh .frame {
    left: 13px;
  }

  .sub_con_app_l_box .app_bottom_sh .frame img {
    width: 265px;
    height: auto;
  }

  .sub_con_app_l_box .app_bottom_sh .pic {
    width: 224px; /*20200706 border-radius 수정*/
    border-radius: 32px;
    overflow: hidden;
  }

  .sub_con_app_l_box .app_bottom_sh .pic img {
    width: 100%;
    height: auto;
  }

  .line_box_left {
    text-align: left;
    margin-top: 70px;
  }

  .line_box_left .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 40px;
    color: #2e3448;
  }

  .line_box_left .left_icon {
    float: left;
    width: 68px;
    height: 68px;
    line-height: 66px;
    border-radius: 50px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(to right bottom, #6d8cce 20%, #77baf5);
    text-align: center;
    margin-right: 30px;
  }

  .sub_con_box .line_box {
    width: 25%;
    margin-top: 70px;
    text-align: center;
    font-size: 17px;
  }

  .sub_con_box .line_box .box_px {
    width: 240px;
  }

  .sub_con_box .line_box .icon_oval {
    width: 90px;
    height: 90px;
    line-height: 86px;
    background: linear-gradient(to right bottom, #4174ee 20%, #1f4bd5);
    box-shadow: 6px 6px 10px rgba(56, 104, 255, 0.32);
    margin: 0 auto;
  }

  .sub_con_in_img img {
    width: 100%;
    height: auto;
  }

  .app_num_box {
    width: 68px;
    height: 68px;
    border-radius: 40px;
    line-height: 66px;
    background: linear-gradient(to right bottom, #4174ee 20%, #1f4bd5);
    box-shadow: 6px 6px 10px rgba(56, 104, 255, 0.32);
    margin: 0 auto;
    font-size: 30px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 28px;
    text-align: center;
  }

  .app_bottom_img {
    width: 670px;
    height: auto;
    margin: 75px auto 0;
    overflow: hidden;
  }

  .app_bottom_img img {
    width: 100%;
    height: auto;
  }

  .app_bottom_img.wide {
    width: 800px;
    position: relative;
  }

  .app_bottom_img.wide .img_info_text {
    right: 16px;
    bottom: 0;
    font-size: 14px;
    z-index: 1;
  }

  .app_bottom_sh {
    width: 500px;
    height: 570px;
    overflow: hidden;
    margin: 55px auto 0;
    position: relative;
    padding-top: 20px;
  }

  .app_bottom_sh .frame {
    position: absolute;
    top: 20px;
    left: 40px;
  }

  .app_bottom_sh .frame img {
    width: 404px;
    height: auto;
  }

  .app_bottom_sh .pic {
    width: 343px;
    height: auto;
    margin: 3px auto 0;
    padding-left: 10px;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 3px 5px 30px rgba(46, 52, 72, 0.3);
  }

  .app_bottom_sh .pic img {
    width: 100%;
    height: auto;
  }

  /* 초빙 페이지 추가 */
  .img_info_text {
    position: absolute;
    color: #a0a4af;
  }

  .con_title .career_new_icon {
    margin-left: 69px;
    border-radius: 10px 10px 10px 0;
    top: 2px;
    font-size: 10px;
    line-height: 10px;
    padding: 2px 6px 4px 6px;
  }

  .chkbox_info {
    line-height: 14px;
    font-size: 11px;
    font-weight: normal;
    margin-top: -15px;
    padding: 0 20px 0 27px;
    word-break: keep-all;
    color: #6c717f;
  }

  /*초빙 신청서*/
  .career_form {
    width: 900px;
    height: auto;
    background-color: #fff;
    margin: 0 auto;
    overflow: auto;
    padding: 0 50px 50px;
  }

  .career_form .title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
    padding-bottom: 40px;
    border-bottom: 2px solid #2e3448;
  }

  .career_form .form_box {
    width: 100%;
    position: relative;
    font-size: 17px;
    clear: both;
    margin-bottom: 20px;
    overflow: auto;
  }

  .career_form .form_box .form_title {
    width: 140px;
    float: left;
    margin-top: 10px;
    font-weight: bold;
  }

  .career_form .form_box .form_input {
    width: calc(100% - 140px);
    float: left;
    font-size: 15px;
  }

  .career_form_input {
    width: 100%;
    height: 46px;
    border: 0;
    border-bottom: 1px solid #dcdfe6;
    font-size: 15px;
  }

  .career_form_input:focus {
    border-bottom: 1px solid #2e3448;
  }

  .career_form_input::-webkit-input-placeholder {
    font-size: 15px;
  }

  .career_form_input:-moz-placeholder {
    font-size: 15px;
  }

  .career_form_input::-moz-placeholder {
    font-size: 15px;
  }

  .career_form_input:-ms-input-placeholder {
    font-size: 15px;
  }

  .career_form_input::placeholder {
    font-size: 15px;
  }

  .career_form_input:focus,
  input:focus {
    outline: none;
  }

  .career_form .imp_icon {
    color: #1f4bd5;
    padding-left: 4px;
  }

  .career_form .form_box .form_input .radio_box {
    width: auto;
    margin-top: 13px;
    padding-left: 2px;
  }

  .career_form .form_box .form_input .radio_box input {
    margin-top: -5px;
  }

  .career_form .form_box .form_input .radio_box label {
    padding-left: 6px;
  }

  .career_form .form_box .form_input .radio_box .disabled {
    color: #c1c3cd;
  }

  .career_form .form_box .form_input .radio_box input.disabled {
    color: #c1c3cd;
    background-color: #edeff1 !important;
  }

  #datepicker {
    width: 160px;
    height: 30px;
    border: 1px solid #dbdde2;
    background-color: #fff;
    font-size: 15px;
    padding: 0 10px;
    margin: -1px 0 0 10px;
    border-radius: 6px;
  }

  /*달력*/
  .ui-widget.ui-widget-content {
    border: 1px solid #dbdde2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button,
  html .ui-button.ui-state-disabled:hover,
  html .ui-button.ui-state-disabled:active {
    border: 1px solid #dbdde2;
    background: #f6f7f9;
    color: #2e3448;
    text-align: center;
  }

  .ui-widget-header {
    border: 1px solid #edeef1;
    background: #fff;
    color: #2e3448;
    font-size: 13px;
  }

  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover,
  .ui-state-focus,
  .ui-widget-content .ui-state-focus,
  .ui-widget-header .ui-state-focus,
  .ui-button:hover,
  .ui-button:focus {
    border: 1px solid #fff;
    background: #fff;
    color: #2b2b2b;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }

  /*자동완성*/
  .ui-menu .ui-menu-item {
    font-size: 15px;
    line-height: 30px;
    border: 1px solid #fff;
  }

  .ui-autocomplete {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  * html .ui-autocomplete {
    height: 200px;
  }

  .ui-menu-item:hover {
    background: #f6f7f9 !important;
    border: 1px solid #f6f7f9;
    color: #2e3448;
  }
}
// ********************************************************************************
@media all and (max-width: 1400px) {
  .app {
    .main_app_s .btn {
      float: left;
      width: 190px;
      height: 70px;
    }
  }
}
@media all and (max-width: 1000px) {
  .app {
    .sub_con_text {
      width: 100%;
    }
  }
}
// ********************************************************************************
@media all and (max-width: 480px) {
  .pc_btn_box {
    display: none;
  }
  .mobile_btn_box {
    margin: 15px;
    display: block;
  } // **************************************

  .app {
    .btn_s {
      width: 100%;
      height: 50px;
      font-size: 17px;
    }

    .btn_box {
      width: auto;
      margin: 28px 18px -22px;
    }

    #wrap {
      min-width: 100%;
    }

    .box {
      width: 100%;
      min-width: 100%;
      margin: 0 auto;
    }

    .mobile {
      display: block;
    }

    .web {
      display: none !important;
    }

    .full_box {
      padding: 50px 0;
    }

    .full_box.sub1 {
      width: auto;
      margin: 18px;
      padding: 0;
      background-color: #fff !important;
      box-shadow: 0 0 15px rgba(46, 52, 72, 0.1);
      background-image: none;
    }

    .full_box.sub1.bottom {
      margin-bottom: 28px;
    }

    .header_scroll .tab_btn,
    .error_header .tab_btn {
    }

    /*header*/

    /*스크롤 상단으로*/
    .scroll_top {
      right: 20px;
      bottom: 20px;
      width: 40px;
      height: 40px;
    }

    .sub_header {
      height: 265px;
      background-image: none;
      position: relative;
      background: linear-gradient(to right bottom, #202532 40%, #1f2a46);
    }

    .sub_header {
      height: 174px;
      background-position: center 78%;
      background-size: 170% auto;
    }

    .sub_header:after {
      height: 174px;
    }

    /*상단 타이틀 제목*/
    .main_title {
      width: 80%;
      margin: 116px auto 0;
      font-size: 24px;
      position: relative;
      top: 0;
      left: 0;
    }

    .main_title.sub {
      margin-top: 96px;
    }

    .main_title p {
      margin-bottom: 14px;
    }

    .main_title .sub {
      font-size: 14px;
    }

    .w_box {
      width: 100%;
      margin: 0 auto;
      padding: 0 0 50px;
      line-height: 24px;
      box-shadow: 0 0 0;
    }

    /*수련병원 배너*/
    .w_box .trainee_ad {
      margin-top: -50px;
      margin-bottom: 50px;
    }

    .sub_tab_box {
      display: none !important;
    }

    .con_title {
      font-size: 18px;
      margin-bottom: 0;
      cursor: pointer;
    }

    .con_title .title_line {
      width: 100px;
      height: 3px;
      margin-top: 20px;
    }

    .full_box.sub1 .con_title {
      text-align: left;
      height: 66px;
      line-height: 66px;
      padding: 0 22px;
      width: auto;
      border-bottom: 1px solid #efeff1;
    }

    .full_box.sub1 .con_title .title_line {
      display: none;
      position: relative;
    }

    .accordian_icon {
      display: block;
      position: absolute;
      right: 22px;
      top: 25px;
    }

    .accordian_icon img {
      width: auto;
      height: 10px;
    }

    .line_box.four {
      width: 100%;
    }

    .line_box {
      float: none;
      width: 100%;
      margin-top: 40px;
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 24px;
      overflow: auto;
      height: auto;
    }

    .line_box .box_px {
      width: 80%;
    }

    .line_box .in_title {
      font-size: 16px;
      margin: 26px auto 12px;
    }

    .main {
      background-color: transparent !important;
    }

    .main .con_title {
      text-align: center;
      margin-left: 0;
    }

    .main .con_title .title_line {
      margin: 30px auto 0;
    }

    .main_app_s {
      width: 220px;
      height: auto;
      overflow: auto;
      margin: 40px auto 40px;
    }

    .main_app_s .btn {
      float: left;
      width: 100%;
      height: 73px;
      border: 1px solid #efeff1;
      box-sizing: border-box;
      margin-right: 0;
      background-repeat: no-repeat;
      background-size: 150px auto;
      margin-bottom: 14px;
    }

    .main_app_slide {
      width: 209px;
      height: 388px;
      position: relative;
      bottom: 0;
      right: 0;
      margin: 0 auto -100px;

      .frame {
        width: 245px;
        height: 348px;
        top: -4px;
        left: -26px;
      }

      img {
        border-radius: 35px;
      }
    }

    .main_app_slide .frame_bg {
      background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 40%,
        rgba(114, 120, 141, 0.4)
      ); /*Safari 5.1-6*/
      background: -o-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 40%,
        rgba(114, 120, 141, 0.4)
      ); /*Opera 11.1-12*/
      background: -moz-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0) 40%,
        rgba(114, 120, 141, 0.4)
      ); /*Fx 3.6-15*/
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 40%,
        rgba(114, 120, 141, 0.4)
      ); /*Standard*/
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .bx-wrapper .bx-pager,
    .bx-wrapper .bx-controls-auto {
      bottom: 80px !important;
    }

    .sub_title_text {
      font-size: 16px;
      line-height: 26px;
      margin: 24px 22px 15px;
      text-align: center;
      word-break: keep-all;
    }

    .sub_title_text img {
      display: none;
    }

    .sub_title_text.box_px {
      text-align: center;
      width: 300px;
      margin: 0 auto;
    }

    .sub_con_box {
      margin-top: 40px;
    }

    .sub_con_box .line_box {
      width: 100%;
      margin-top: 0;
      padding-bottom: 40px;
      font-size: 14px;
    }

    .sub_con_box .line_box .box_px {
      width: 270px;
    }

    .sub_con_box .line_box .icon_oval {
      margin: 0 auto;
    }

    .sub_con_box .line_box .icon_oval img {
      width: 56px;
    }

    .line_box_left {
      margin-top: 0;
      margin-bottom: 40px;
    }

    .line_box_left .title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .line_box_left .left_icon {
      margin-right: 20px;
    }

    .line_box_left .text_box {
      width: auto;
      margin-left: 88px;
    }

    .full_box.sub1 .sub_con_text {
      font-size: 14px;
      line-height: 23px;
      width: auto;
      margin: 30px 22px 30px;
      text-align: left;
      letter-spacing: -0.5px;
    }

    .sub_con_text.left_pa {
      padding-left: 0;
    }

    .sub_con_text.app {
      width: 300px;
      margin: 0 auto;
      text-align: center;
      margin-top: 12px;
      font-size: 14px;
      line-height: 24px;
    }

    .sub_con_app_l_box {
      width: 300px;
      float: none;
      margin: 0 auto;
    }

    .sub_con_app_l_box .sub_title_text {
      text-align: center;
      width: 300px;
      margin-top: 40px;
      margin-right: 0;
      padding-bottom: 12px;
    }

    .sub_con_app_l_box .sub_con_text {
      text-align: center;
      width: 300px;
      margin-right: 0;
    }

    .sub_con_app_l_box.r_box {
      float: none;
      width: 300px;
    }

    .sub_con_app_l_box.r_box .sub_title_text {
      margin-left: 0;
    }

    .sub_con_app_l_box.r_box .sub_con_text {
      margin-left: 0;
    }

    .sub_con_app_l_box.l_box .sub_title_text {
      margin-left: 0;
      margin: 40px auto 0;
    }

    .sub_con_app_l_box.l_box .sub_con_text {
      margin-left: 0;
      margin: 0 auto;
    }

    .sub_con_app_l_box {
      width: 100%;
      float: none;
    }

    .sub_con_app_l_box .sub_title_text {
      text-align: center;
      width: 300px;
      margin: 40px auto 0;
    }

    .sub_con_app_l_box .sub_con_text {
      text-align: center;
      width: 300px;
      margin: 0 auto 0;
    }

    .sub_con_app_l_box.r_box .sub_title_text {
      margin-left: 0;
    }

    .sub_con_app_l_box.r_box .sub_con_text {
      margin-left: 0;
    }

    .sub_con_app_l_box .app_bottom_sh {
      width: 300px;
      height: 366px;
      margin: 20px auto 0;
      position: relative;
      padding-top: 20px;
    }

    .sub_con_app_l_box .app_bottom_sh .frame {
      position: absolute;
      top: 20px;
      left: 11px;
    }

    .sub_con_app_l_box .app_bottom_sh .frame img {
      width: 270px;
      height: auto;
    }

    .sub_con_app_l_box .app_bottom_sh .pic {
      width: 230px;
      margin: 3px auto 0;
      border-radius: 40px;
    }

    .sub_con_app_l_box .app_bottom_sh .pic img {
      width: 100%;
      height: auto;
    }

    .sub_con_in_img img {
      display: none;
    }

    .sub_con_in_img.app img {
      display: block;
    }

    .m_bg_bottom {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 40%
      );
      bottom: 0;
      height: 80px;
    }

    /*20210806 내용 전체 수정*/

    .road_map_bg {
      width: auto;
      margin: 30px auto 40px;
      height: 100%;
      margin-left: 28px;

      .road_map_in {
        .year_box {
          min-width: 45px;
          max-width: 45px;
          height: 44px;
          line-height: 44px;
          font-size: 15px;
          margin-left: -25px;
          margin-right: 5px;

          &.now {
            background-color: #1f4bd5;
            box-shadow: 2px 2px 15px rgba(20, 61, 191, 0.7);
          }
        }

        .line {
          display: none;
        }

        .line.isYearDuplicate {
          display: block;
          width: 25px;
        }

        .month {
          float: none;
          margin-right: 0;
          font-size: 17px;
        }

        .text {
          float: none;
          margin-left: 25px;
          text-indent: -1em;
          width: 80%;

          .title {
            font-size: 15px;
            margin-top: 6px;
            margin-bottom: 3px;
            text-indent: 0;
            margin-left: -8px;
          }
        }
      }
    }

    .road_map_bg .road_map_in.no_year .year_box {
      margin-left: -7.5px;
      margin-top: 18px;
    }

    .road_map_bg .road_map_in.no_year .line {
      display: block;
      width: 0;
      margin-top: 23px;
      padding-left: 20px;
    }

    .app_num_box {
      margin-top: 0;
    }

    .app_num_box.m_top_ma {
      margin-top: 40px;
    }

    .app_bottom_img {
      width: 300px;
      height: auto;
      margin: 40px auto 0;
    }
    .app_bottom_sh {
      width: 300px;
      height: 366px;
      margin: 20px auto 0;
      position: relative;
      padding-top: 20px;
    }

    .app_bottom_sh .frame {
      position: absolute;
      top: 20px;
      left: 11px;
    }

    .app_bottom_sh .frame img {
      width: 270px;
      height: auto;
    }

    .app_bottom_sh .pic {
      width: 230px;
      margin: 3px auto 0;
      border-radius: 40px;
    }

    .bo_btn_s {
      margin-right: 0;
      margin-left: 6px;
    }

    .bo_btn_s.list {
      margin-right: 6px;
    }

    /* 초빙 페이지 추가 */
    .sub_title_text.no_title {
      margin-top: 0;
    }

    .career_info_img {
      width: 310px;
      margin: 24px auto 0;
    }

    .career_info_img .career_img_box {
      width: 100%;
      float: none;
      margin-bottom: 14px;
    }

    .career_info_img .career_img_box p {
      font-size: 14px;
      width: 94%;
      margin: -10px auto 0;
    }

    .career_info_img .career_img_box p.min {
      font-size: 13px;
      margin-top: 4px;
    }

    .career_info_img img {
      width: 100%;
    }

    .career_easy_img {
      .career_img_box {
        .app_bottom_sh {
          width: 100%;
          height: 370px;
          padding-top: 14px;
          margin: 24px auto 0;

          .pic {
            width: 224px;
            border-radius: 30px;
            box-shadow: 3px 5px 18px rgba(46, 52, 72, 0.3);
            padding-left: 7px;
          }

          .frame {
            top: 14px;
            left: 50%;
            margin-left: -137px;

            img {
              width: 264px;
            }
          }
        }
      }
    }
  }
}
// END *********************** 480px ***********************
@media all and (max-width: 340px) {
  /* 초빙 페이지 추가 */
  .career_benefit_bg {
    height: 210px;
    margin-top: 0;
  }
  .career_benefit_bg .text_box {
    width: 90%;
    margin: 26px auto 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.8px;
  }
  .career_benefit_bg .text_box .point {
    font-weight: 500;
    font-size: 24px;
  }
  .career_con_btn {
    margin-top: 20px;
  }
}
