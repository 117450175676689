#features {
  //background-color: red!important;
  #contents{
    .full_box{
      display: flex;
      flex-direction: column;
    }
  }
  .app_bottom_sh {
    .frame {
      left: 20px;
      img {
        width: 252px;
      }

      &.big{
        left: 45px;
        img{
          width: 395px;
        }
      }
    }
    .pic{
      width: 226px;
      &.big{
        width: 345px;
      }
    }
  }
  #real_name_club{
    padding: 0;
  }
}
@media all and (max-width: 480px) {
  .app{
    .sub_title_text.box_px{
      width: inherit;
    }
  }
  #features {
    .app_bottom_sh {
      .frame {
        left: 17px;
        img {
          width: 259px;
        }

        &.big{
          left: 17px;
          img{
            width: 259px;
          }
        }
      }
      .pic, .pic.big{
        width: 230px;
      }
    }
  }
}
@media all and (max-width: 340px) {
  #features{
    .box{
      //padding:5vw;
      overflow: hidden;
      .sub_title_text{
        margin: 40px auto 0 auto;
      }
      .sub_con_text.app{
        width: 90vw;
        margin:auto;
      }
      .app_bottom_img{
        width: 90vw!important;
      }
      .sub_con_app_l_box{
        width: 90vw;
      }
    }
    .app_bottom_sh{
      width: 270px;
      .frame {
        left: 0!important;
        img{
          width: 259px;
        }
      }
      .pic, .pic.big{
        box-shadow: 2px 1px 18px rgba(46, 52, 72, 0.3);
        width: 235px;
      }
    }
  }
}

@media all and (max-width: 300px) {
  #features{
    .box{
      .sub_title_text{
        width: 90vw;
      }
      .sub_con_app_l_box{
        width: 100vw;
      }
    }
    .app_bottom_sh{
      .frame {
        img{
          width: 258px;
        }
      }
    }
  }
}

@media all and (max-width: 280px) {
  #features{
    .app_bottom_sh{
    }
  }
}