.mo_sub1_accordion{
  display: block;
}
#BrandValue{
  .box{
    overflow: hidden;
    .mo_sub1_accordion{
      .icon_oval{
        img, svg{
          width: 56px;
          display: inline-block;
          margin: auto;
        }

      }
    }
  }
}
.road_map_bg {
  width: 665px;
  margin: 80px auto 100px;
  border-left: 3px solid #e4e6e9; /*20210806 height 수정*/
  height: auto;

  .road_map_in {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &.no_month{
      margin-top: -5px;
    }

    .year_box {
      width: 80px;
      height: 80px;
      line-height: 78px;

      background-color: #2e3448;
      border-radius: 40px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      float: left;
      margin-left: -42px;
      box-shadow: 2px 2px 10px rgba(46, 52, 72, 0.4);

      &.now{
        background-color: #1f4bd5;
        box-shadow: 2px 2px 15px rgba(20, 61, 191, 0.7);
      }


    }
    .year_dot{
      width: 12px;
      height: 12px;
      background-color: #E4E6E9;
      margin-left: -7.5px;
      border-radius: 50%;
    }

    .line {
      width: 140px;
      border-bottom: 2px dotted #e4e6e9;
      //height: 40px;


      &.isYearDuplicate { // 라인 없음
        width: 175px;
      }
    }

    .month {
      float: left;

      margin-right: 36px;
      margin-left: 14px;
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      color: #2e3448;
    }

    .text {

      float: left;
      text-align: left; /*20200706 width 추가*/
      width: 400px;

      .title {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
        margin-top: 26px;
        color: #2e3448;

        &.isMonthDuplicate {
          margin-left: 255px;
          width: 100%;
          margin-top: 0;
          //&::before{
          //  content: "\00B7";
          //}
        }
      }
    }
  }
}

.road_map_bg .road_map_in .text .indent {
  text-indent: -10px !important;
  margin-left: 0 !important;
}

/*20200706 추가*/
.road_map_bg .road_map_in.no_year .year_box {
  width: 12px;
  height: 12px;
  background-color: #e4e6e9;
  margin-left: -7.5px;
  margin-top: 35px;
  box-shadow: 0 0 0 !important;
}

.road_map_bg .road_map_in.no_year .line {
  padding-left: 34px;
}

.road_map_bg .road_map_in.now .line {
  border-bottom: 2px dotted #1f4bd5;
}

.road_map_bg .road_map_in.now .month {
  color: #1f4bd5;
}

.road_map_bg .road_map_in.now .text .title {
  color: #1f4bd5;
}

.road_map_bg .road_map_in.now.no_year .year_box {
  background-color: #1f4bd5;
}

.road_map_bg .road_map_in.next .year_box {
  background-color: #afb2bd;
  box-shadow: 2px 2px 10px rgba(46, 52, 72, 0.2);
}

.road_map_bg .road_map_in.next .month {
  color: #6c717f;
  font-size: 16px;
  margin-right: 29px;
}

.road_map_bg .road_map_in.next .text .title {
  color: #6c717f;
}

.full_box.roadmap_bg {
  background-image: url(../images/roadmap_bg.png);
  background-repeat: no-repeat;
  background-position: top -100px right -200px;
}

// *******************************************************
@media all and (max-width: 480px) {
  .mo_sub1_accordion{
    max-height: 0;
    overflow:hidden;
    transition:max-height 0.7s linear; // 1100

    .line_box:nth-child(4){
      margin-bottom: 40px;
      padding-bottom: 0;
    }
  }

  .m_bg_bottom{
    display: none;
  }

  .road_map_bg{
    .road_map_in{
      .year_dot{
        width: 8px;
        height: 8px;
        margin-left: -5px;
      }
      .text{
        .title.isMonthDuplicate{
          margin-left: 50px !important;
        }
      }
    }
  }
  #BrandValue{
    .mo_sub1_accordion{
      //padding-bottom: 20px;
    }
  }
}
@media all and (max-width: 400px) {
  .app{
    .road_map_bg {
      //margin-left: ;
      .road_map_in {
        flex-wrap: wrap;
        padding-bottom: 30px;
        margin-bottom: 0;

        &.no_month{
          margin-top: -20px;
        }
        .month {
          margin-top: -25px;
          margin-left: 3px;
        }

        .text {
          margin-left: 30px;
          margin-top: 0;

          &.no_year_box{
            margin-top: -13px;
          }
          .title {
            margin-top: 0;
            margin-left: 0;
            &.isMonthDuplicate {
              margin-left: 0!important;
            }
          }
        }
      }
    }
  }
}