@import 'src/variables';
.C_Survey {
  .c_mb_100 {
    margin-bottom: 100px;
  }
  .c_mt_100 {
    margin-top: 100px;
  }
  .c_max_width {
    max-width: 1280px;
  }
  .header {
    .header_bg_img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 390px;
      color: white;
      text-align: center;
      font-size: 44px;
      font-weight: 700;
    }
    .tab_box {
      display: flex;
      flex-direction: row;
      margin: auto;
      background-color: $bg_grey_color;
      color: $text_black_color;
      .each_tab_box {
        cursor: pointer;
        height: 100px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $bg_dark_grey_color;
        }

        svg {
          margin-bottom: 4px;
        }
        p {
          color: $text_black_color;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content_box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content_box_header {
        width: 100%;
        background-color: white;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          color: $text_black_color;
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 28px;
        }
        .h1_bottom_box {
          width: 130px;
          height: 3px;
          background: rgba(46, 52, 72, 0.08);
          margin-bottom: 80px;
        }
      }
      .content_box_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.bg_grey {
          background-color: $bg_grey_color;
          //background-color: red;
        }
        &.bg_white {
          background-color: white;
        }
        .number {
          width: 68px;
          height: 68px;
          background: linear-gradient(
            135deg,
            #4174ee 5.73%,
            #627fda 5.73%,
            #1f4bd5 87.55%
          );
          filter: drop-shadow(6px 6px 10px rgba(56, 104, 255, 0.32));
          border-radius: 50%;

          color: white;
          display: flex;
          justify-content: center;
          align-items: center;

          text-align: center;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 28px;
        }
        h2 {
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0.183px;
          margin-bottom: 14px;
          &.black {
            color: $text_black_color;
          }
          &.point {
            color: $point_color;
          }
        }
        p {
          text-align: center;
          opacity: 0.7;
          font-size: 17px;
          font-weight: 400;
        }
        .image_box {
          margin: 75px 0 0 0;
          &.list_pc {
            position: relative;
            display: flex;
            flex-direction: row;
            .item {
              padding: 0 20px;
              .frame {
                overflow: hidden;
                width: 300px;
                height: 400px;
                position: relative;
                margin: auto;
                .aaa {
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, 0%);
                  z-index: 2;
                  overflow: hidden;
                }
                .bbb {
                  position: absolute;
                  top: 12px;
                  left: 53%;
                  transform: translate(-50%, 0%);
                  z-index: 1;
                  width: 86%;
                  border-radius: 20px;
                }
              }
            }
          }

          &.list_mobile {
            position: relative;
            display: none;
            .main_app_slide {
              bottom: 0;
              right: 0;
            }
          }
        }
      }
      &.bg_img {
        width: 100%;
        img {
          width: 100%;
          height: 600px;
        }
      }
      &.email {
        width: 100%;
        padding: 100px;
        color: white;
        align-items: start;
        .c_max_width {
          width: 100%;
          margin: auto;
          .title {
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 12px;
          }
          .text {
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.5px;
            margin-bottom: 30px;
          }
          button {
            display: flex;
            padding: 14px 40px 16px 40px;
            color: white;

            border-radius: 10px;
            background: linear-gradient(123deg, #4174ee 11.37%, #1f4bd5 84.17%);
            box-shadow: 0 0 12px 0 rgba(31, 75, 213, 0.3);

            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .C_Survey {
    .c_max_width {
      max-width: unset;
      width: 100%;
    }
    .content {
      .content_box {
        .content_box_body {
          .image_box.list_pc {
          }
        }
      }
    }
  }
}
@media all and (max-width: 767px) {
  .C_Survey {
    .c_mb_100 {
      margin-bottom: 50px;
    }
    .c_mt_100 {
      margin-top: 50px;
    }
    .c_max_width {
      max-width: unset;
      width: 100%;
    }
  }
}
@media all and (max-width: 480px) {
  .C_Survey {
    .header {
      .header_bg_img {
        height: 174px;
        font-size: 24px;
        padding-top: 56px;
      }
      .tab_box {
        display: none;
      }
    }
    .content {
      .content_box {
        .content_box_header {
          padding-top: 50px;
          h1 {
            font-size: 18px;
            margin-bottom: 20px;
          }
          .h1_bottom_box {
            margin-bottom: 24px;
          }
        }
        .content_box_body {
          padding: 0 24px;
          h2 {
            font-size: 16px;
          }
          p {
            font-size: 14px;
          }
          .image_box {
            margin: 24px 0 0 0;
            &.list_pc {
              display: none;
            }
            &.list_mobile {
              display: flex;
              height: 430px;
              width: 100%;
              .main_app_slide {
                bottom: 0;
                left: 50% !important;
                transform: translate(-50%, 0%) !important;
                position: absolute !important;
                .slick-dots {
                  bottom: 90px !important;
                }
              }
            }
          }
        }
        &.bg_img {
          img {
            height: auto;
          }
        }
        &.email {
          padding: 24px 0;
          .c_max_width {
            padding: 0 24px;
            .title {
              font-size: 16px;
            }
            .text {
              font-size: 14px;
            }
            button {
              display: flex;
              justify-content: center;
              padding: 15px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
