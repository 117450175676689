#trainee_main_nav{
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right bottom, #202532 40%, #1f2a46);
  overflow: hidden;
  padding-top: 40px;

  .swiper-wrapper{

  }

  #top_box{
    display: flex;
    flex-direction: row;
    line-height: 70px;
    padding-left: 6vw;
    padding-right: 6vw;

    .trainee_title{
      display: flex;
      flex-grow: 1;
      a{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;

        img{
          width:190px; height: 36px;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 10px;
        }
        p{
          font-size: 1.2rem;
        }
      }
    }

    .top_hospital_btn{
      cursor: pointer ;
      width: 220px;
      font-size: 17px;
      height: 44px;
      line-height: 42px;
      text-align: center;
      text-indent: 34px;
      color: #fff;
      margin-top: auto;
      margin-bottom: auto;
      background-image: url(../../images/trainee/icon/top_hospital_icon.svg);
      background-repeat: no-repeat;
      background-position: left 10px center;
      background-size: 30px;

      &.not_active{
        border: 1px solid rgba(255,255,255,0.3);
        &:hover{
          background-color: #fff;
          color: #262629;
          background-image: url(../../images/trainee/icon/top_hospital_icon_hover.svg);
          border: 1px solid #fff; box-shadow: 0 0 6px rgba(255,255,255,0.6);
          -moz-transition: all .2s ease;
          -o-transition: all .2s ease;
          -ms-transition: all .2s ease;
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
        }
      }

      a{
        color: #fff;
        line-height: 44px;
        margin-left: 60px;
      }
    }
  }

  // active
  .top_hospital_btn.active{
    background-color:#1F4BD5; border: 1px solid #1F4BD5; box-shadow: 0 0 6px rgba(31,75,213,0.6);
  }

  #middle_box{
    &.mobile{
      ul{
        .item{
          //width: calc(100vw / 3)!important;
          width: calc(100px)!important;


        }
      }
    }

    &.not_mobile{
      ul{
        .item{
          width: 170px!important;
          &:hover{
            color: #fff;
            -moz-transition: all .2s ease;
            -o-transition: all .2s ease;
            -ms-transition: all .2s ease;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
            p{
              color: #888d97;
              -moz-transition: all .2s ease;
              -o-transition: all .2s ease;
              -ms-transition: all .2s ease;
              -webkit-transition: all .2s ease;
              transition: all .2s ease;
            }
          }
        }
      }
    }

    display: flex;
    margin: 35px auto auto;

    ul{
      display: flex;
      flex-direction: row;
      cursor: pointer;

      .item{
        position: relative;


        height: 66px;

        color: #888d97;
        text-align: center;
        margin-right: 0;
        p{
          font-size: 13px;
          color:#656a73;
        }
        span, .text{
          font-size: 17px;
        }
        .bar{
          display: none;
        }

        &.active{
          p{
            color:#888d97;
            -moz-transition: all .2s ease;
            -o-transition: all .2s ease;
            -ms-transition: all .2s ease;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
          }
          span, .text{
            color: #fff;
            -moz-transition: all .2s ease;
            -o-transition: all .2s ease;
            -ms-transition: all .2s ease;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
          }
          .bar{
            display: block;
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;
            background-color: #FBD991;
            box-shadow: 0 0 10px #ffe80f;
          }
        }
      }
    }
    &.mobile{
      display: none;
    }
  }
}
// =======================================================================================================
@media all and (max-width: 1200px) {

}
@media all and (max-width: 830px) {
  #trainee_main_nav {
    #top_box {
      justify-content: center;
      flex-wrap: wrap;
      .trainee_title{
        a{
          flex-wrap: wrap;
          justify-content: center;
          p{
              margin-top: 5px;
              margin-bottom: 15px;
          }
        }
      }
    }
  }
}
// START *************************** 0px ~ 500px ***********************************
@media all and (max-width: 480px) {
  #trainee_main_nav {
    padding-top: 0;
    #top_box {
      margin-top: 15px;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
      line-height: inherit;

      .top_hospital_btn{
        background-size: 22px;
        margin-top: 16px;
        font-size: 14px;
        text-align: right;
        padding-right: 10px;

        height: 34px;
        line-height: 34px;
        width: 172px;
      }
      .trainee_title {
        a{
          flex-direction: column;
          p{
            margin: 0;
          }
          img{
            margin-right: 0;
            width: 155px;
          }
        }
      }
    }

    #middle_box{
      &.mobile{
        height: 45px;
        margin: 0 auto auto;
        display: block;
        width: 100%;
        ul{
          display: block;
          .item{
            display: inline-block;
            height: 45px;
            .text{
              margin-top: -4px;
              font-size: 15px;
            }
          }
        }
      }
      &.not_mobile{
        display: none;
      }
    }
  }
} // END *************************** 0px ~ 500px ***********************************
@media all and (max-width: 281px) {
  #trainee_main_nav{
    #middle_box{
      &.mobile{
        ul{
          .item{
            height: 45px;
          }
        }
      }
    }
  }
}