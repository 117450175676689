#aaa {
  display: flex;
  flex-direction: row;
}
#aaa img {
  width: 201px;
}
#bbb {
  display: flex;
  flex-direction: column;
}
#ccc {
  margin: 51px auto 5px !important;
}

/*상단 타이틀 배경*/
.main_header {
  height: 600px;
  background-image: url(../../images/main/main_header_bg.jpg);
  background-position: -50px 60%;
  background-repeat: no-repeat;
  background-size: 107% auto;
  overflow: auto;
}
.main_header:after {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 600px;
  background: linear-gradient(
    to bottom,
    rgba(36, 37, 44, 0.4) 10%,
    rgba(0, 0, 0, 0)
  );
}

.C_MainPopupModalButton {
  button {
    width: 50%;
    height: 40px;
    background-color: #1b44c8;
    color: white;
  }
}

@media all and (max-width: 480px) {
  .main_header {
    height: 320px;
    background-image: url(../../images/main/main_header_bg.jpg);
    background-position: 45% 38%;
    background-size: 173% auto;
  }
  .main_header:after {
    height: 320px;
  }

  #bbb {
    display: flex;
    flex-direction: column;
    width: 100%;
    #ccc {
      font-size: 18px;
    }
  }
  #qr_image_box {
    display: none;
  }
  .main {
    .slick-arrow {
      display: none;
    }
    .slick-dots {
      bottom: 7px !important;
    }
  }
}
@media all and (max-width: 300px) {
  .main_header {
    height: 295px;
  }
}
