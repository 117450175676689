.main-slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: end;
  width: 100%;
  bottom: 7px !important;
  background: linear-gradient(
    180deg,
    rgba(46, 52, 72, 0) 0%,
    rgba(46, 52, 72, 0.2) 100%
  );
  z-index: 10;
  margin-left: 1px;
  li {
    width: 10px;
    height: 10px;
    top: -10px;
    margin: 0 5px;
    button::before {
      font-size: 10px;
      top: -8px;
      width: 10px;
      height: 10px;
      line-height: 0;
      border-radius: 10px;
      line-height: 10px;
    }
  }
  li.slick-active {
    button:before {
      text-shadow: #1b44c8 2px 2px 10px;
      bottom: 21px !important;
      top: -8px;
    }
    button {
      width: 10px;
      height: 10px;
    }
  }
}

.main_banner_mobile {
  .slick-arrow {
    display: flex !important;
    background-color: #e9eaef;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
  }
  .slick-prev {
    left: -64px;
    @media all and (max-width: 340px) {
      left: -40px;
    }
  }
  .slick-next {
    right: -64px;
    @media all and (max-width: 340px) {
      right: -40px;
    }
  }

  .slick-prev:before {
    content: url('../../images/prevArrow.svg');
  }
  .slick-next:before {
    content: url('../../images/nextArrow.svg');
  }
}
