@import "../../variables.scss";
#notice {

  // START **************** notice list item **************************
  #contents{
    overflow:hidden;
    .notice_container_box{
      width: 1100px;
      margin: 100px auto 100px auto;

      .notice_item_box {
        width: 1000px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(49, 61, 102, 0.05);
        display: flex;
        margin: 0 auto 15px auto;

        .text_box {
          width: 936px;
        }

        .title {
          width: 936px;
          font-size: 16px;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .date {
          font-size: 12px;
          color: #abaeb6;
          margin-top: 4px;
        }

        // 현재는 내용 안보이는 버전으로 변경
        //.text {
        //  color: #6c717f;
        //  font-size: 13px;
        //  line-height: 22px;
        //  display: -webkit-box;
        //  -webkit-line-clamp: 2;
        //  -webkit-box-orient: vertical;
        //  word-break: break-all;
        //  max-height: 98px;
        //  overflow: hidden;
        //  white-space: normal;
        //  height: 3.2em;
        //}

        &:hover{
          .title {
            font-weight: 600;
            text-decoration: underline;
          }

          //.text {
          //  color: #2e3448;
          //}
        }
        .new_icon {
          display: none;
        }
      }
      .btn_box{
        margin-top: 60px;
      }
    }
  }// END **************** notice list item **************************


  // start ******************************************
  .notice_detail_box {
    width: 1000px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(49, 61, 102, 0.05);
    margin: 10px auto;
    overflow: auto;

    .text_box {
      width: 900px;
      margin: 40px auto;
      height: auto;

      .title {
        font-size: 1.3rem;
        line-height: 30px;
        font-weight: 600;
      }

      .date {
        font-size: 13px;
        color: #abaeb6;
        margin-top: 12px;
        margin-bottom: 36px;
      }

      .text {
        width: 100%;
        height: auto;
        padding-top: 36px;
        border-top: 1px solid #efeff1;
        font-size: 14px;
        line-height: 1.7em;

        img {
          max-width: 100%;
          text-align: left;
          width: auto;
          height: auto;
          margin: 20px 0;
        }
      }
    }
    .btn_box {
      margin: 40px auto;
      text-align: left;
    }

  }// END ******************************************
}
// START *************************** 0px ~ 500px ***********************************
@media all and (max-width: 480px) {
  #notice {
    .full_box{
      padding:0;
    }
    #contents {
      overflow: hidden;

      .notice_container_box {
        width: 100%;
        margin: 15px auto 15px auto;

        .notice_item_box {
          padding: 15px 0 15px 0;
          position: relative;

          width: 90%;
          overflow: hidden;

            .text_box {
              width: 100%
            }

            .title {
              width:90vw;
              font-size: 1.1rem;
              padding: 0 10px 0 15px;
            }

            .date {
              display: none;
            }

            &:hover {
              .title {
                font-weight: 600;
                text-decoration: underline;
              }
            }

            .new_icon {
              display: block;
              position: absolute;
              width: 6px;
              height: 6px;
              background-color: #1F4BD5;
              border-radius: 3px;
              box-shadow: 0 0 6px #1f4bd5;
              top: 12px;
              left: 12px;
            }
        }
        .btn_box{
          margin: 0 auto;
        }
      }
    }
    #pressBtnBox{
      width: 90vw;
      margin: auto;
      button{
        margin: 0;
      }
    }
  }

  // 공지사항 상세 페이지
  #notice{
    .notice_detail_box {
      width: calc(100vw - 30px);
      margin: 15px;

      .text_box {
        margin:0;
        width: 100%;
        padding: 15px;

        // TODO : 모바일 크기 조절 2022-12-30
        .title {
          font-size: 16px;
          line-height: 26px;
        }

        .date{
          font-size: 13px;
          color: #abaeb6;
          margin-top: 12px;
          margin-bottom: 24px;
        }

        .text {
          width: 100%;
          height: auto;
          padding-top: 36px;
          border-top: 1px solid #efeff1;
          font-size: 14px;
          line-height: 1.7em;

          img {
            max-width: 100%;
            text-align: left;
            width: auto;
            height: auto;
            margin: 20px 0;
          }
          p{
            word-break: break-all!important;
          }
        }
      }
      .btn_box {
        margin: 20px 0 0 0;
        height: auto;
        text-align: left;
      }
    }
  }
}// **********************************width 281px ~ 500px **********************************************


@media all and (max-width: 280px) {
  #notice{
    .notice_detail_box{
      margin: 15px;
      overflow: hidden;

      .text_box{
        padding: 10px;
        .title{
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}