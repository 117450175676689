@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
/* region ************************** 전체 스타일 **************************************/
body{
  overflow: auto;
  min-width: 1100px;
}
body, input, textarea, select, table, button {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: #2e3448;
}
img, fieldset {
  border: 0;
}
img, input, select, textarea {
  vertical-align: middle;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
strong {
  font-weight: bold;
}
caption, th {
  text-align: left;
}
abbr, acronym {
  border: 0;
}
/* END ************************** 전체 스타일 **************************************/
.blue_btn{
  width: 110px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 5px;
  background-color: #4568FF;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  margin-top: 20px;
}
.image_modal {
  width: 500px;
  height: 500px;
  margin: auto;

  max-height: 90vh;
  background-color: #E0EAF5;
  color: #2E3448;
  font-size: 14px;
  font-weight: 500;

  background-size:588px 100%;
  background-position:100px 152px;
  background-repeat:no-repeat;

  position: relative;
  overflow: hidden;

  .ext_btn{
    cursor: pointer;
    position: absolute;
    top: 7%;
    right: 7%;
  }

  .image_modal_content{
    width: 100%;
    height: 100%;

    .content_box{
      width: 400px;
      margin: 50px auto;
      text-align: left;
      .title{
        font-size:24px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      img{
        margin-bottom:14px;
      }
    }
  }
}

/*스크롤 상단으로 이동 버튼*/
.scroll_top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: none;
  z-index: 90;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.scroll_top img {
  width: 100%;
  height: auto;
}

// 로딩 페이지
#loading{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .text{
    font-size: 1rem;
  }
  img{
    height: 80px;
    width: 80px;
  }
}
// 에러 페이지

// START *************************** 0px ~ 500px ***********************************
@media all and (max-width: 500px) {
  html{
    font-size: 13px;
  }
} // END *************************** 0px ~ 500px ***********************************
@media all and (max-width: 480px) {
  body{
    min-width: unset;
    width: 100vw;
  }
}
@media all and (max-width: 330px) {
  .chartjs-size-legend {
    font-size: 5px !important; /* 범례 폰트 크기 조정 */
  }
}
#chartjs-tooltip {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}