.box{
  //background-color: red;
}
#career{
  .pa_bottom_0{
    background-color: #f6f7f9;
  }
  .image_box{
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.mobile{
      display: none;
    }
  }
}

.career_info_img {
  width: 1100px;
  text-align: center;
  margin: 50px auto 20px;
  overflow: hidden;
  display: flex;

  .career_img_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 33.3%;

    p {
      font-size: 16px;
      &.min{
        font-size: 14px;
        color: #a0a4af;
        margin-top: 4px;
      }
    }
  }
  img {
    width: 330px;
    height: auto;
  }
}

.career_easy_img {
  width: 1100px;
  height: auto;
  text-align: center;
  margin: 50px auto 0;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  .career_img_box {
    width: 25%;
    float: left;
    display: block;
    overflow: hidden;

    .app_bottom_sh {

      width: 100%;
      height: 370px;
      padding-top: 14px;
      margin: 24px auto 0;

      .pic {
        width: 226px;
        border-radius: 30px;
        box-shadow: 3px 5px 18px rgba(46, 52, 72, 0.3);
        padding-left: 7px;
      }

      .frame {
        top: 14px;
        left: 5px;
        img {
          width: 257px;
        }
      }
    }
  }
}
.career_easy_img .img_info_text {
  right: 24px;
  top: 0;
  font-size: 13px;
}

.career_easy_img .main_app_slide {
  display: none;
}

.career_benefit_bg {
  background-image: url(../images/career_benefit_bg.jpg);
  background-size: 100% auto;
  background-position: 60% 55%;
  height: 400px;

  overflow-x: hidden;
  overflow-y: auto;

  margin-top: -30px;
}
.career_benefit_bg .text_box {
  width: 980px;
  margin: 80px auto 0;
  color: #fff;
  font-size: 32px;
  line-height: 44px;
  opacity: 90%;
  font-weight: 300;
  letter-spacing: -1.2px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.career_benefit_bg .text_box .point {
  font-weight: 500;
  font-size: 38px;
}
.career_con_btn {
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  overflow: auto;
  background: linear-gradient(to right bottom, #4174ee 20%, #1f4bd5);
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 1px 1px 5px rgba(49, 92, 226, 0.7);
  font-weight: 400;
  cursor: pointer;
}

.career_edu_btn {
  margin: 0 auto;
  width: 400px;
  height: auto;
  background-color: #1f4bd5;
  opacity: 90%;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -200px;
  top: 170px;
  padding: 14px 0 18px 0;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  box-shadow: 1px 1px 10px rgba(31, 75, 213, 0.5);
  cursor: pointer;
  z-index: 1;
}
.career_edu_btn p {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.career_new_icon {
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: 3px;
  background-color: #1f4bd5;
  color: #fff;
  font-size: 8px;
  line-height: 8px;
  padding: 2px 4px 4px 4px;
  border-radius: 10px;
  font-weight: 600;
}

@media all and (max-width: 768px) {
  #career{
    .image_box{
      &.web{
        display: none;
      }
      &.mobile{
        display: flex;
      }
    }
  }
}

@media all and (max-width: 480px) {
  #career_popup{}
  #career{
    .app_bottom_img{
      margin-top: 40px;
    }
    .career_new_icon{
        margin-left: 46px;
    }
    .career_edu_btn{
      p{
        font-size: 18px;
      }
    }
  }
  .pc_career_img_box{
    display: none;
  }
  .career_easy_img{
    .main_app_slide{
      display: block;
    }
    .img_info_text{
      position: unset;
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  .career_info_img{
    width:100%;
    display: flex;
    flex-direction:column;
    .career_img_box {
      width: 100%;
    }
  }
  .career_easy_img{
    width:100%;
    height: 334px;
  }

  .app_bottom_img {
    width: 300px!important;
  }
  .career_edu_btn{
    width: 220px;
    margin-left: -110px;
    top: 50px;
    font-size: 13px;
    padding: 10px 0 14px 0;
  }

  // 모바일 슬라이드 버튼
  .slick-dots{
    bottom: 104px!important;
  }
  .career_benefit_bg {
    height: 240px;
    margin-top: 0;

    .text_box {
      width: 90%;
      margin: 26px auto 0;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.8px;

      .point {
        font-weight: 500;
        font-size: 24px;
      }
    }
  }

}// END *********************** max-width: 480px ***********************
@media all and (max-width: 350px) {
  #career{
    .career_benefit_bg{
      background-size: 130% auto;
    }
  }
}
@media all and (max-width: 340px) {
  /* 초빙 페이지 추가 */
  .career_benefit_bg {
    height: 210px;
    margin-top: 0;
  }
  .career_benefit_bg .text_box {
    width: 90%;
    margin: 26px auto 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.8px;
  }
  .career_benefit_bg .text_box .point {
    font-weight: 500;
    font-size: 24px;
  }
  .career_con_btn {
    margin-top: 20px;
  }
}
@media all and (max-width: 300px) {
  #career{
    .sub_con_text.app{
      width: 90vw;
    }
    .career_info_img{
      width: 90vw!important;
    }
    .app_bottom_img{
      width: 90vw!important;
    }
  }
}
@media all and (max-width: 281px) {
  #career{
    .box{
      overflow: hidden;
    }
    .app{
      .sub_con_text{
        &.app{
          width: 90vw;
        }
      }
    }
    .career_info_img{
      width: 90vw;
    }

  }
}